import Vue from 'vue'
import Vuex from 'vuex'

const fb = require('./firebaseConfig.js')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    uid: '',
    loggedIn: false,
    isTest: false,
    banner: {
      active: false,
      color: '#6f41ff',
      text: 'Registriere dich, um alle Vorteile von Deeperly zu nutzen!',
      button: "Registrieren",
      route: '/register'
    },
    user: {},
    lists: [],
    actions: [],
    view: 'w80'
  },
  mutations: {
    setUid(state, uid) {
      state.uid = uid;
    },
    setUserIsLoggedIn(state) {
      state.loggedIn = true
    },
    setUserIsLoggedOut(state) {
      state.loggedIn = false
    },
    setLists(state, docArr) {
      state.lists = [];
      state.latestListViewed = null;
      state.lists = docArr;
    },
    addList(state, list) {
      state.lists.push(list);
    },
    addActionList(state, list) {
      state.actions.push(list);
    },
    removeList(state, lid) {
      let listindex = state.lists.findIndex(element => {
        return element.id == lid
      })
      if (listindex > -1) {
        state.lists.splice(listindex, 1)
      }
    },
    setActions(state, docArr) {
      state.actions = [];
      state.latestListViewed = null;
      state.actions = docArr;
    },
    setViewTo50(state) {
      state.view = 'w50'
    },
    setViewTo80(state) {
      state.view = 'w80'
    },
    setUserObject(state, uobj) {
      state.user = uobj
    },
    switchBanner(state, visibility) {
      state.banner.active = visibility;
    }
  },
  actions: {
    logInUser(state, uid) {
      return new Promise((resolve, reject) => {
        state.commit('setUid', uid);
        state.commit('setUserIsLoggedIn');
        state.dispatch('fetchUserData', uid).then(user => {

          if (user.subscription !== undefined) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer sk_test_Yq7Pimbo0xJoqhFycFY82yWA00WAg9Y8Gj");

            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };

            fetch("https://api.stripe.com/v1/subscriptions/" + user.subscription, requestOptions)
                .then(response => response.text())
                .then(result => {
                  if (result.status === 'active') {
                    state.dispatch('fetchList', uid);
                    state.dispatch('fetchActions');
                    resolve('ok')
                  } else {
                    console.log("payment-hook (not active yet)");
                    state.dispatch('fetchList', uid);
                    state.dispatch('fetchActions');
                    resolve('ok') // TODO: change to 'pay'
                  }
                })
                .catch(error => console.log('error', error));
          } else {
            console.log("payment-hook (not active yet)");
            //console.log("redirect to payment, no subscription at all");
            state.dispatch('fetchList', uid);
            state.dispatch('fetchActions');
            resolve('ok') // TODO: change to 'pay'
          }
        })
      })
    },
    logOutUser(state) {
      state.commit('setUid', '');
      state.commit('setUserIsLoggedOut');
      state.lists = [];
      state.actions = [];
      fb.auth.signOut().then(function() {
        // Sign-out successful.
      }).catch((error) => {
        console.log("Sign Out Error:", error);
      });
    },
    fetchUserData(state, uid) {
      return new Promise((resolve, reject) => {
        fb.db.collection("users").doc(uid).get().then((docs) => {
          state.commit('setUserObject', docs.data());
          resolve(docs.data());
        }).catch(() => {
          reject()
        })
      })
    },
    fetchList(state, uid) {
      return new Promise((resolve, reject) => {
        fb.db.collection("lists").where("owner", "==", uid).get().then( (docs) => {
          let listarr = []
          docs.forEach(doc => {
            let docCompl = {
              ...doc.data(),
              id: doc.id
            }
            listarr.push(docCompl);
          })

          let promarr = []
          // complete reference exercises
          listarr.forEach( (itm, ind) => {
            promarr.push(new Promise((resolve2, reject2) => {
              if (itm.root == false) {
                fb.db.collection("lists").doc(itm.rootExId).get().then((docs) => {
                  listarr[ind].value = docs.data().value
                  listarr[ind].description = docs.data().description
                  listarr[ind].list = docs.data().list
                  listarr[ind].name = docs.data().name // just in case, when it changes
                  resolve2()
                }).catch(() => {
                  console.error("Error happened")
                  reject2()
                })
              } else {
                resolve2()
              }
            }))
          })

          Promise.all(promarr).then((values) => {
            state.commit('setLists', listarr);
            resolve()
          });

          //state.commit('setLists', listarr);
          //resolve()
        }).catch((error) => {
          console.log("Error getting lists-document:", error);
          reject();
        });
      })
    },
    fetchActions(state) {
      return new Promise((resolve, reject) => {
        fb.db.collection("actions").get().then(function(docs) {
          let actarr = []
          docs.forEach(doc => {
            let docCompl = {
              ...doc.data(),
              id: doc.id
            }
            actarr.push(docCompl);
          })
          console.log(actarr)
          state.commit('setActions', actarr);
          resolve()
        }).catch((error) => {
          console.log("Error getting actions-document:", error);
          reject()
        });
      });
    },
    fetchAnonymousExercise(state, exid) {
      return new Promise((resolve, reject) => {
        fb.db.collection("lists").doc(exid).get().then((docs) => {
          let listarr = {
            ...docs.data(),
            id: exid
          }

          var newList = null;
          // trimme für die Vorschau TODO: Auflösen
          /*if (listarr.value.length >= 5) {
            listarr.value = listarr.value.slice(0, 5);
          }*/

          fb.db.collection("actions").doc(listarr.list.trim()).get().then((listdoc) => {

            let actarr = {
              ...listdoc.data(),
              id: listdoc.id
            }

            // trimme für die Vorschau TODO: Auflösen
            /*if (actarr.actions.length >= 5) {
              actarr.actions = actarr.actions.slice(0, 5);
            }*/

            state.commit('setActions', [actarr]);
            state.commit('setLists', [listarr]);

            resolve()
          }).catch(error => {
            console.log("Error fetching doc: ", error);
            reject()
          })

        }).catch(error => {
            console.log("Error fetching doc: ", error);
            reject()
        });
      })
    },
    fetchTemporaryExercise(state, exid) {
      return new Promise((resolve, reject) => {
        fb.db.collection("lists").doc(exid).get().then((docs) => {
          let listarr = {
            ...docs.data(),
            id: exid
          }
          state.commit('addList', listarr);
          resolve()
        }).catch(error => {
          console.log("Error fetching doc: ", error);
          reject()
        });
      })
    },
    copyListReferenceToOwnCollection(state, exid) {
      return new Promise((resolve, reject) => {
        fb.db.collection("lists").doc(exid).get().then((docs) => {
          console.log(docs);
          console.log(docs.data());
          console.log(state)

          let rootex = docs.data();
          let ex = {
            owner: state.state.uid,
            /*name: rootex.name + ' (Kopie)', TODO: Eventuell mit 'Kopie'? */
            name: rootex.name,
            rootExId: docs.id,
            rootOwner: rootex.owner,
            root: false,
            created: Date.now(),
            edited: Date.now()
          }

          fb.db.collection("lists").add(ex).then((docs) => {
            state.commit('removeList', exid);
            state.commit('addList', ex);
            resolve()
          }).catch(err => {
            console.log('Error setting doc: ', err);
            reject()
          })

        }).catch(error => {
          console.log("Error fetching doc: ", error);
          reject()
        });
      })
    },
    addList(state, newListObj) {
      return new Promise((resolve, reject) => {
        fb.db.collection("lists").add(newListObj).then(() => {
          state.commit('addList', newListObj);
          resolve(newListObj)
        })
            .catch(error => {
              console.log('Add list failed', error);
              reject();
            });
      })
    },
    addActionList(state, newListObj) {
      return new Promise((resolve, reject) => {
        fb.db.collection("actions").add(newListObj).then(() => {
          state.commit('addActionList', newListObj);
          resolve(newListObj)
        })
        .catch(error => {
          console.log('Add actions failed', error);
          reject();
        });
      })
    },
    removeList(state, exid) {
      return new Promise((resolve, reject) => {
        fb.db.collection("lists").doc(exid).delete().then(() => {
          state.commit('removeList', exid);
          resolve()
        })
        .catch(error => {
          console.log('Remove list failed', error);
          reject();
        });
      })
    },
    renewUserSubscription(state, subID) {
      console.log(state.state.uid)
      return new Promise((resolve, reject) => {
        fb.db.collection("users").doc(state.state.uid).get().then((docs) => {
          var aYearFromNow = new Date();
          aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
          console.log(aYearFromNow.getTime())
          fb.db.collection("users").doc(state.state.uid).set({
            ...docs.data(),
            nextPayment: aYearFromNow.getTime(),
            subscription: subID,
            pro: true
          }).then(() => {
            resolve(true);
          })
        }).catch(() => {
          reject()
        })
      })
    },
    cancelUserSubscription(state, subID) {
      console.log(state.state.uid)
      return new Promise((resolve, reject) => {
        fb.db.collection("users").doc(state.state.uid).get().then((docs) => {
          fb.db.collection("users").doc(state.state.uid).set({
            ...docs.data(),
            nextPayment: Date.now(),
            subscription: '',
            pro: false
          }).then(() => {
            resolve(true);
          })
        }).catch(() => {
          reject()
        })
      })
    }
  },
  modules: {

  }
})
