<template>
  <div class="area">
      <div class="center" id="features">
          <div class="left">
            <h2>Was ist Deeperly?</h2>
          </div>
          <div class="right">
              <div class="fact">
                  <h3>Begriffe-Trainer</h3>
                  <p>Mit Deeperly können abstrakte, komplexe und zusammenhängende Lerninhalte selbständig vertieft werden. Mit einer Begriffsliste deiner Wahl kannst du starten. Während dem Lernen werden zufällig Begriffe angezeigt. Die ebenfalls zufällig angezeigte Aktion soll mit dem Begriff ausgeführt werden.</p>
              </div>
              <div class="fact">
                  <h3>Assoziativ</h3>
                  <p>Der Aufbau ist so gewählt, dass die Begriffe mit bereis gelernten Lerninhalten <strong>verknüpft</strong> werden müssen. Assoziatives Lernen ist gehirngerecht und die schnellste Methode, Inhalte aufzunehmen.</p>
              </div>
              <div class="fact">
                  <h3>Abwechslungsreich</h3>
                  <p>Durch die verschiedenen Aktionen wird Deeperly sehr abwechslungsreich. Die Begriffe kommen unter umständen mehr mals vor, jedoch immer mit neuen Aktionen. Und sollte einmal die Aktion nicht passen, kann sie natürlich übersprungen werden.</p>
              </div>
          </div>
      </div>
      <hr>
      <div class="example">
          <h3>Beispiel</h3>
          <div class="example-word">
              <span>"Deeperly"</span>
              <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z"/></svg>
              </strong>
              <p>Schreiben Sie eine inhaltlich richtige<br>"Blick"-Schlagzeile mit diesem Begriff.</p>
          </div>
      </div>
      <hr>
  </div>
</template>

<script>
export default {
    name: 'area-what'
}
</script>

<style scoped lang="scss">

.area {
    width: 100%;
    margin-bottom: 130px;

    @include mobile-down {
        margin-bottom: 0px;
    }
}

.example {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        font-weight: 800;
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .example-word {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @include mobile-down {
            flex-direction: column;
        }

        span {
            font-weight: 800;
            font-size:1.5rem;
            margin-right: 20px;
            color: #12dafc;

            @include mobile-down {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        strong {
            margin-right: 20px;
            @include mobile-down {
                transform: rotate(90deg);
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        p {
            text-align: left;
            margin-bottom: 0;
        }
    }
}

.center {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    padding-bottom: 0;

    @include mobile-down {
        flex-direction: column;
        padding: 0px;
        padding-top: 140px;
        width: 100%;
    }

    .left {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include mobile-down {
            width: 100%;
            padding-right: 0px;
            padding-bottom: 60px;
        }

        h2 {
            font-size: 4rem;
            font-weight: 800;
            margin-bottom: 20px;

            @include mobile-down {
                font-size: 3rem;
            }
        }
    }

    .right {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        text-align: left;

        @include mobile-down {
            
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-left: 0px;
            width: 100%;
        }

        .fact {
            width: 50%;
            margin: 30px;

            @include mobile-down {
                width: 80%;
            }

            h3 {
                font-size: 1.3rem;
                font-weight: 800;
                margin-bottom: 5px;
            }
        }
    }
}

</style>