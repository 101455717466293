<template>
    <div class="area">
        <img src="@/assets/deeperly.svg" class="logo">
        <div class="login-button" @click="$router.push('/login')">
            {{login}}
        </div>
        <div class="center" id="start">
            <div class="left">
                <img src="@/assets/phone.png">
                <h1>Deep.<br>Deeper.<br>De<div class="reflect">e</div>perly.</h1>
            </div>
            <div class="right">
                <div class="right-box">
                    <h1>Deep.<br>Deeper.<br>De<div class="reflect">e</div>perly.</h1>
                    <h2>Vertiefe deine komplexen Inhalte <br> mit kreativen Aktionen.</h2>
                    <p>Grosse Zusammenhänge zu erklären ist die Königsdisziplin beim Lernen. Ein Thema hat man verstanden, wenn man es seiner Grossmutter erklären oder in Zusammenhang mit anderen Lerninhalten verbinden kann. Starte auch du mit deinem Lerninhalt und <strong>werde Experte</strong>!</p>
                    <div class="login-button flexible" @click="$router.push('/register')">
                        Ausprobieren
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'area-landing',
    computed: {
        login() {
            if (this.$store.state.loggedIn) {
                return 'Dashboard';
            } else {
                return 'Login';
            }
        }
    },
}
</script>

<style scoped lang="scss">

.area {
    width: 100%;
    min-height: 100vh;

    .logo {
        position: absolute;
        top: 40px;
        left: 40px;
        height: 40px;

        @include mobile-down {
            height: 35px;
        }
    }
}

.reflect {
    display: inline-block;
    transform-origin: 0.5 0.5;
    transform: rotateY(180deg);
}

.center {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    min-height: 100vh;

    @include mobile-down {
        flex-direction: column;
        padding-top: 140px;
        width: 100%;
    }

    .left {
        width: 40%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        @include mobile-down {
            width: 100%;
        }

        img {
            width: 380px;

            @include mobile-down {
                width: 50%;
                transform: translate(-40%);
            }
        }

        h1 {
            display: none;
        
            @include mobile-down {
                width: 50%;
                display: block;
                text-align: left;
                font-weight: 800;
                font-size: 3rem;
                transform: translate(-40%);
            }
        }
    }

    .right {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;

        @include mobile-down {
            width: 100%;
            text-align: center;
            align-items: center;
        }


        .right-box {
            width: 55%;
            
            @include mobile-down {
                width: 85%;
            }

            h1 {
                font-size: 4rem;
                font-weight: 800;
                margin-bottom: 20px;

                @include mobile-down {
                    display: none;
                }
            }

            h2 {
                font-size: 1.3rem;
                font-weight: 800;
                margin-bottom: 30px;
            }

            p {
                margin-bottom: 40px;
            }
        }
    }
}

.login-button {
    position: absolute;
    top: 40px;
    right: 40px;

    background-color: white;
    color: #14CCEC;
    border-radius: 23px;
    box-shadow: rgba($color: #000000, $alpha: 0.2) 0 0 30px;
    font-weight: 800;
    font-size: 25px;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;

    &.flexible {
        position: relative;
        top: inherit;
        right: inherit;
        display: inline;
    }

    @include mobile-down {
        top: 32px;
        box-shadow: rgba($color: #000000, $alpha: 0.2) 0 0 20px;
        font-size: 18px;
        padding: 10px;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 8px;
    }
}
</style>