<template>
    <div class="nav-bar" :class="{visible: (scrollTop > 300) }">
        <div class="nav-logo" @click="$route.push('/home')">
            <img src="@/assets/deeperly.svg">
        </div>
        <div class="nav-menu">
            <router-link class="nav-menu-item" to="#start" @click.native="scrollFix('#start')">Start</router-link>
            <router-link class="nav-menu-item" to="#features" @click.native="scrollFix('#features')">Features</router-link>
            <router-link class="nav-menu-item" to="#preis" @click.native="scrollFix('#preis')">Preis</router-link>
            <div class="login-button" @click="$router.push('/login')">
                {{login}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'nav-bar',
    data() {
        return {
            scrollTop: 0
        }
    },
    mounted () {
        window.addEventListener('scroll', (e) => {
            this.scrollTop = window.scrollY
        });
    },
    methods: {
        scrollFix: function(hashbang) {
            location.hash = hashbang;
        }
    },
    computed: {
        login() {
            if (this.$store.state.loggedIn) {
                return 'Dashboard';
            } else {
                return 'Login';
            }
        }
    },
}
</script>

<style scoped lang="scss">
.nav-bar {
  width: 100%;
  height: 100px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  position: fixed;
  top: -100px;
  left: 0;
  z-index: 70;
  box-shadow: rgba($color: #000000, $alpha: 0) 0 0 20px;
  transition: all 0.4s ease;

  @include mobile-down {
      flex-direction: column;
      justify-content: center;
  }

  &.visible {
      top: 0px;
      box-shadow: rgba($color: #000000, $alpha: 0.5) 0 0 20px;
  }

  .nav-logo {

    img {
      height: 30px;
    }
  }

  .nav-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    a {
        color: black;
    }

    .nav-menu-item {
      margin-left: 10px;
      margin-right: 10px;
      text-transform: uppercase;
      font-weight: 800;
      border-bottom: rgba($color: blue, $alpha: 0) 1px solid;
      transition: all 0.4s ease;
      cursor: pointer;

      &:hover {
        border-bottom: rgba($color: blue, $alpha: 1) 1px solid;
      }
    }

    .login-button {
        background-color: white;
        color: #14CCEC;
        border-radius: 10px;
        box-shadow: rgba($color: #000000, $alpha: 0.2) 0 0 30px;
        font-weight: 800;
        font-size: 21px;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;

        cursor: pointer;
        
        margin-left: 30px;
    }
  }
}
</style>