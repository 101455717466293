<template>
  <div id="app">
    <div class="banner" v-if="$store.state.banner.active" :style="'background-color:' + $store.state.banner.color">{{$store.state.banner.text}} <router-link class="btn btn-light" style="margin-left: 20px;" :to="$store.state.banner.route">{{$store.state.banner.button}}</router-link></div>

    <div class="content-start" v-if="$route.name == 'home' || $route.name == 'impressum'">
      <router-view/>
    </div>
    <div class="content-frame" :class="{ bannerspace: $store.state.banner.active }" v-else>
      <img src="@/assets/deeperly.svg" class="logo" @click="$router.push('/')">
      <div class="content-box" :class="$store.state.view">
        <router-view/>
      </div>
      <div class="ad-box" :class="$store.state.view">
        <Adsense
            data-ad-client="ca-pub-6187164752829647"
            data-ad-slot="5422573608"
            data-ad-format="auto"
            data-full-width-responsive="true"
        >
        </Adsense>
      </div>
    </div>
    <bubbles></bubbles>
  </div>
</template>

<script>

import Bubbles from '@/components/Bubbles.vue';
export default {
  name: 'app',
  data() {
    return {
      width50: true,
      width80: false
    }
  },
  components: {
    Bubbles
  },
}
</script>

<style lang="scss">

#app {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  color: #2c3e50;
  background-color: white;
  overflow: hidden;
  position: relative;
}

.banner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
  box-shadow: rgba($color: #000000, $alpha: 0.3) 0 0 20px;

  @include mobile-down {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
}

.content-start {
  position: relative;
  z-index: 2;
}

.content-frame {
  position: relative;
  overflow: auto;
  z-index: 2;
  padding-top: 10%;
  padding-bottom: 5%;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  min-height: 100vh;

  &.bannerspace {
    min-height: calc(100vh - 100px);
  }

  @include mobile-down {
    padding-top: 100px;
  }



  .content-box {
    background-color: white;
    box-sizing: border-box;
    border-radius: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: rgba($color: #000000, $alpha: 0.3) 0 0 40px;
    z-index: 3;

    position: relative;

    width: 50%;

    &.w50 {
      width: 50%;
    }

    &.w80 {
      width: 80%;
    }

    @include mobile-down {
      width: 80%;
      margin-bottom: 130px;

      &.w50 {
        width: 90%;
      }

      &.w80 {
        width: 95%;
      }
    }
  }

  .ad-box {
    background-color: white;
    width: 100%;
    max-width: 30%;
    height: 500px;
    margin-left: 5%;

    &.w50 {
      width: 50%;
      margin-left: 5%;
    }

    &.w80 {
      width: 15%;
      margin-left: 1.8%;
    }

    @include mobile-down {
      position: fixed;
      bottom: 0;
      left: 0;
      margin-left: 0;
      max-width: 100%;
      width: 100%;
      height: 100px;
      z-index: 999;

      &.w50 {
        width: 100%;
        margin-left: 0%;
      }

      &.w80 {
        width: 100%;
        margin-left: 0%;
      }
    }

  }

  .logo {
    position: absolute;
    top: 40px;
    left: 40px;
    height: 50px;
    cursor: pointer;

    @include mobile-down {
      height: 25px;
      top: 40px;
      left: 30px;
    }
  }
}
</style>
