<template>
    <div class="bubble-window">
        <!-- <img src="@/assets/formen/form1.svg" id="bubble1" style="top: -11%; right: -19%; transform: rotate(10deg);" ref="bubble1" class="bubble w50">
        <img src="@/assets/formen/form1.svg" id="bubble1" style="top: -60%; left: 80%; transform: rotate(-90deg);" ref="bubble1" class="bubble w50">
        <img src="@/assets/formen/form2.svg" id="bubble2" style="top: 80%; left: 10%; transform: rotate(-20deg);" ref="bubble2" class="bubble w30">
        <img src="@/assets/formen/form3.svg" id="bubble3" style="top: 50%; left: 70%;" ref="bubble3" class="bubble w40">
        <img src="@/assets/formen/form4.svg" id="bubble4" style="top: -8%; left: -16%; transform: rotate(153deg);" ref="bubble3" class="bubble w50">
        <img src="@/assets/formen/form6.svg" id="bubble6" style="top: 84%; left: 65%; width: 4%" ref="bubble6" class="bubble">
        <img src="@/assets/formen/form7.svg" id="bubble6" style="top: 20%; left: 75%;" ref="bubble6" class="bubble w10">
        <img src="@/assets/formen/form7.svg" id="bubble6" style="top: 24%; left: 70%; width: 3%" ref="bubble6" class="bubble">
        <img src="@/assets/formen/form7.svg" id="bubble6" style="top: 35%; left: 72%; width: 1.5%" ref="bubble6" class="bubble"> -->
        <img src="@/assets/bg.svg" class="bg">
    </div>
</template>

<script>
export default {
    name: 'bubbles',
    mounted () {

    },
    methods: {
        place(bubble) {
            let randX = "";
            if (Math.floor(Math.random() * 10) > 5) {
                randX = Math.floor(Math.random() * 10) + 1 + '%';
            } else {
                randX = Math.floor(Math.random() * 10) + 81 + '%';
            }

            let randY = "";
            if (Math.floor(Math.random() * 10) > 5) {
                randY = Math.floor(Math.random() * 10) + 1 + '%';
            } else {
                randY = Math.floor(Math.random() * 10) + 81 + '%';
            }

            this.$refs[bubble].style.top = randX;
            this.$refs[bubble].style.left = randY;
        }
    },
}
</script>

<style lang="scss">

.bubble-window {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.bubble {
    position: absolute;
    width: 20%;
    z-index:  0;
    
    &.w10 {
        width: 10%;
    }
    &.w30 {
        width: 30%;
    }
    &.w40 {
        width: 40%;
    }
    &.w50 {
        width: 50%;
    }
}
</style>