<template>
  <div class="home">
    <nav-bar></nav-bar>
    <area-landing></area-landing>
    <area-what></area-what>
    <area-reasons></area-reasons>
    <area-testimonials></area-testimonials>
    <area-price></area-price>
    <area-footer></area-footer>
  </div>
</template>

<script>
import NavBar from '@/components/startseite/Nav.vue'
import AreaLanding from '@/components/startseite/Landing.vue'
import AreaWhat from '@/components/startseite/What.vue'
import AreaReasons from '@/components/startseite/Reasons.vue'
import AreaTestimonials from '@/components/startseite/Testimonials.vue'
import AreaPrice from '@/components/startseite/Price.vue'
import AreaFooter from '@/components/startseite/Footer.vue'

export default {
  name: 'home',
  components: {
    NavBar, AreaLanding, AreaReasons, AreaTestimonials, AreaPrice, AreaFooter, AreaWhat
  },
  mounted () {
    if (!this.$store.state.loggedIn) {
      this.$store.commit('switchBanner', false);
    }
  },
}
</script>

<style scoped lang="scss">

.home {
  position: relative;
}

</style>