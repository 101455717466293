<template>
  <div class="area">
      <div class="test-box">
          <img src="@/assets/person.jpg">
          <div class="text">
              <h4>"Lernen ist anstrengend. Das Gefühl etwas zu können jedoch berauschend!"</h4>
              <p>Samuel Rhyner - Creator of Deeperly</p>
          </div>
      </div>
  </div>
</template>

<script>

export default {
    name: 'area-testimonials'
}
</script>

<style scoped lang="scss">

.area {
    width: 100%;
    min-height: 40vh;
}

.test-box {
    width: 50%;
    background-color: white;
    border-radius: 30px;
    min-height: 150px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 20px;
    box-shadow: rgba($color: #000000, $alpha: 0.2) 0 0 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include mobile-down {
        flex-direction: column;
        width: 90%;
    }

    img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 200px;
        margin-right: 25px;

        @include mobile-down {
            margin-right: 0;
        }
    }

    .text {
        width: 50%;
        height: 100%;
        text-align: left;

        @include mobile-down {
            width: 90%;
            text-align: center;
            margin-top: 30px;
        }

        h4 {
            font-size: 1.4rem;
            font-weight: 800;
        }

    }
}

</style>