import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const fb = require('../store/firebaseConfig.js')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component:  () => import(/* webpackChunkName: "about" */ '../views/Impressum.vue')
  },
  {
    path: '/abo',
    name: 'abo',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payment/:err?',
    name: 'payment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Payment.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/learn/:id',
    name: 'learn',
    component: () => import(/* webpackChunkName: "about" */ '../views/Learn.vue')
  },
  {
    path: '/new',
    name: 'new',
    component: () => import(/* webpackChunkName: "about" */ '../views/New.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/newactions',
    name: 'newactions',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/info/:what',
    name: 'info',
    component: () => import(/* webpackChunkName: "about" */ '../views/Info.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import(/* webpackChunkName: "about" */ '../views/Success.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.name !== null) {
    const currentUser = fb.auth.currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if (requiresAuth && !currentUser && to.name !== 'success') {
      next('/login')
    } else {
      next()
    }
  }
})

export default router
