<template>
  <div class="area">

      <div class="center" id="features">
          <div class="left">
            <h2>Wieso<br>Deeperly?</h2>
          </div>
          <div class="right">
              <div class="fact">
                  <h3>Persönlich</h3>
                  <p>Erstelle in Deeperly deine eigenen Lerninhalte und füge sie deinem Dashboard hinzu. Deine Begriffsliste kannst du z.B. von deinem Lehrer in Deeperly übernehmen.</p>
              </div>
              <div class="fact">
                  <h3>Shareable</h3>
                  <p>Lernen ist ein sozialer Prozess. Wenn es die Situation erfordert kannst du deine Liste sharen und andere Deeperly-Nutzer können die Liste in ihr Repertoire aufnehmen.</p>
              </div>
              <div class="fact">
                  <h3>Kreative Aktionen</h3>
                  <p>Es gibt schon viele vorgefertigte, kreative Aktionen. Wenn du aber gerne deine eigenen erstellen willst, kein Problem!</p>
              </div>
              <div class="fact">
                  <h3>Simpel</h3>
                  <p>Deeperly ist wirklich simpel. Wenig Text und Fokus auf das Wichtige: Deine Lerninhalte. Auch in Sachen Geschwindigkeit und Design wurden keine Abstriche vorgenommen.</p>
              </div>
              <div class="fact">
                  <h3>App</h3>
                  <p>Logge dich auf deinem Handy in Deeperly ein und füge es auf deinem Startbildschirm hinzu. Somit kannst du bequem von Unterwegs lernen.</p>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'area-reasons'
}
</script>

<style scoped lang="scss">

.area {
    width: 100%;
    min-height: 100vh;
}

.center {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    min-height: 50vh;
    padding: 50px;

    @include mobile-down {
        flex-direction: column;
        padding: 0px;
        padding-top: 140px;
        width: 100%;
    }

    .left {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: right;
        padding-right: 30px;

        @include mobile-down {
            width: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding-right: 0px;
            padding-bottom: 60px;
        }

        h2 {
            font-size: 4rem;
            font-weight: 800;
            margin-bottom: 20px;

            @include mobile-down {
                font-size: 3rem;
            }
        }
    }

    .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        padding-left: 30px;

        @include mobile-down {
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-left: 0px;
            width: 100%;
        }

        .fact {
            width: 50%;
            margin-bottom: 30px;

            @include mobile-down {
                width: 80%;
            }

            h3 {
                font-size: 1.3rem;
                font-weight: 800;
                margin-bottom: 5px;
            }
        }
    }
}

</style>