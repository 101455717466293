import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@tweenjs/tween.js';

const fb = require('./store/firebaseConfig.js')

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Ads from 'vue-google-adsense'


Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component('back', () => import('./components/Back.vue'))

Vue.config.productionTip = false

const vm = this;
const app = new Vue({
  data() {
    return {
      payment: false
    }
  },
  router,
  store,
  render: h => h(App)
})

fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.dispatch('logInUser', user.uid).then(act => {
      if (act === 'pay') {
        app.payment = true;
      }
      app.$mount('#app')
    });
  } else {
    store.dispatch('logOutUser').then(() => {
      console.log('user is logged out');
      app.$mount('#app')
    });
  }
});
