<template>
  <div class="area">
      <h4>Deeperly</h4>
      <p>Made with ❤️ in <img src="https://www.countryflags.io/ch/flat/24.png" style="position: inline;"> by <a href="https://codecrush.ch/" target="_blank">code crush</a></p>
      <br><br>
      <router-link to="/impressum">Impressum</router-link>
      <br><br>
  </div>
</template>

<script>
export default {
    name: 'area-footer'
}
</script>

<style scoped lang="scss">

.area {
    width: 100%;
    min-height: 30vh;
    background-color: #14CCEC;
    border-top: solid 10px #FFD74F;
    padding-top: 50px;
    color: white;

    a {
        color: white;
    }
}

</style>
